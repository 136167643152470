<template>
  <div class="row form-data">
    <b-form :form="form" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="CNPJ" class="input-loading">
            <b-input
              :disabled="!isAdd"
              v-model="$v.form.cnpj.$model"
              :class="invalid_cnpj && 'is-invalid'"
              @change="isAdd ? getCnpj() : null"
              v-mask="'##.###.###/####-##'"
              placeholder="CNPJ"
            />
            <b-icon icon="disc" v-if="loadingCnpj" />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Razão Social">
            <b-form-input
              disabled
              :class="$v.form.social_reason.$error && 'is-invalid'"
              v-model="form.social_reason"
              placeholder="Razão Social"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Nome Fantasia">
            <b-form-input
              disabled
              v-model="form.fantasy_name"
              placeholder="Nome Fantasia"
            />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Atividade Principal">
            <b-form-input
              :class="$v.form.segment.$error && 'is-invalid'"
              v-model="form.segment"
              placeholder="Atividade Principal"
            />
          </b-form-group>
        </div>
        <div class="row px-3">
          <div class="col-md-4">
            <b-form-group label="CEP" class="input-loading">
              <b-form-input
                :class="$v.form.zip_code.$error && 'is-invalid'"
                v-model="form.zip_code"
                @keyup="getZipCode"
                v-mask="'#####-###'"
                placeholder="CEP"
              />
              <b-form-invalid-feedback v-if="$v.form.zip_code.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
              <b-icon icon="disc" v-if="loading_zip_code" />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Cidade">
              <b-form-input
                v-model="$v.form.city.$model"
                :class="$v.form.city.$error && 'is-invalid'"
                placeholder="Cidade"
              />
              <b-form-invalid-feedback v-if="$v.form.city.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="UF">
              <b-form-input
                v-model="form.state"
                :class="$v.form.state.$error && 'is-invalid'"
                placeholder="UF"
              />
              <b-form-invalid-feedback v-if="$v.form.state.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Logradouro">
              <b-form-input
                v-model="form.place"
                :class="$v.form.place.$error && 'is-invalid'"
                placeholder="Logradouro"
              />
            </b-form-group>
            <b-form-invalid-feedback v-if="$v.form.place.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </div>
          <div class="col-md-2">
            <b-form-group label="Número">
              <b-form-input
                v-model="form.number"
                :class="$v.form.number.$error && 'is-invalid'"
                placeholder="Número"
              />
              <b-form-invalid-feedback v-if="$v.form.number.$error">
                Campo obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Complemento">
              <b-form-input
                v-model="form.complement"
                placeholder="Complemento"
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Bairro">
              <b-form-input
                v-model="form.neighborhood"
                :class="$v.form.neighborhood.$error && 'is-invalid'"
                placeholder="Bairro"
              />
            </b-form-group>
            <b-form-invalid-feedback v-if="$v.form.neighborhood.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4">
          <b-form-group label="Contato">
            <b-form-input
              v-model="form.phone"
              :class="$v.form.phone.$error && 'is-invalid'"
              placeholder="Contato"
            />
            <b-form-invalid-feedback v-if="$v.form.phone.$error">
              Campo obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Email">
            <b-form-input
              v-model="$v.form.email.$model"
              :class="$v.form.email.$error && 'is-invalid'"
              placeholder="empresa@email.com"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.email.email || !$v.form.email.required"
            >
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <!-- <div class="col-md-4">
          <b-form-group label="E-mail responsável legal">
            <b-form-input
              v-model="form.responsible_email"
              :class="$v.form.responsible_email.$error && 'is-invalid'"
              placeholder="empresa@email.com"
            />
            <b-form-invalid-feedback v-if="$v.form.responsible_email.$error">
              {{ $t("auth.type-valid-email") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.form.responsible_email.sameAsEmail"
            >
              Os e-mail devem ser diferentes
            </b-form-invalid-feedback>
          </b-form-group>
        </div> -->
      </div>

      <div class="mt-5 d-flex w-100 justify-content-between" v-if="!isAdd">
        <b-button
          variant="outline-danger"
          class="mr-3"
          @click="handleConfirmDelete()"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Excluir filial</span>
        </b-button>

        <div class="d-flex align-items-center gap-3">
          <b-button @click="$emit('submited')" variant="light">
            <span>Cancelar</span>
          </b-button>
          <b-button
            :disabled="isDisabled() || loadingSubmit"
            type="submit"
            variant="primary"
          >
            <b-spinner v-if="loadingSubmit" small class="mr-2" />
            <span>Salvar filial</span>
          </b-button>
        </div>
      </div>
      <div v class="mt-5 mx-3 d-flex w-100 justify-content-end" v-else>
        <b-button
          :disabled="isDisabled() || loadingSubmit"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Salvar filial</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, not } from "vuelidate/lib/validators";
import { getCnpj } from "@/utils/validations/getCnpj";

export default {
  mixins: [validationMixin],
  directives: { mask },
  props: {
    branchId: {
      type: [Number, String],
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      role: "",
      disableCheck: false,
      invalid_cnpj: false,
      valid_cnpj: false,
      form: {
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
        // responsible_email: null,
        email: null,
        cnpj: null,
      },
      loadingSubmit: false,
      loadingCnpj: false,
      loading_zip_code: false,
    };
  },
  validations: {
    form: {
      cnpj: { required },
      social_reason: { required },
      segment: { required },
      zip_code: { required },
      city: { required },
      state: { required },
      place: { required },
      number: { required },
      neighborhood: { required },
      phone: { required },
      email: {
        required,
        email,
      },
      // responsible_email: {
      //   required,
      //   email,
      //   sameAsEmail: not(sameAs("email")),
      // },
    },
  },
  computed: {
    ...mapGetters(["user", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions([
      "show_toast",
      "get_legal_personnel",
      "add_provider",
      "edit_provider",
      "add_borrower",
      "edit_borrower",
      "show_delete",
      "delete_provider",
      "delete_borrower",
    ]),
    isDisabled() {
      if (this.invalid_cnpj || !this.form.cnpj) {
        return true;
      }
      return false;
    },
    isDisabledInput(element) {
      return !(this.valid_cnpj && !element);
    },

    getCnpj() {
      this.resetForm();
      this.loadingCnpj = true;
      const cnpj = this.form.cnpj.replace(/[^\d]/g, "");
      getCnpj(cnpj)
        .then((data) => {
          if (data.status !== "ERROR") {
            this.valid_cnpj = true;
            this.invalid_cnpj = false;
            this.form = {
              ...this.form,
              cnpj: data.cnpj,
              social_reason: data.nome,
              fantasy_name: data.fantasia,
              segment: data.atividade_principal[0].text,
              zip_code: data.cep,
              city: data.municipio,
              state: data.uf,
              place: data.logradouro,
              number: data.numero,
              complement: data.complemento,
              neighborhood: data.bairro,
              email: data.email,
              phone: data.telefone,
            };
          } else {
            this.valid_cnpj = false;
            this.invalid_cnpj = true;
            this.resetForm();
          }
          this.loadingCnpj = false;
        })
        .catch(() => {
          this.loadingCnpj = false;
          this.invalid_cnpj = true;
          this.resetForm();
        });
    },
    getZipCode() {
      let zipCode = this.form.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              if (data.erro) {
                this.invalid_cep = true;
              } else {
                this.invalid_cep = false;
              }
              this.responsible_data = data;
              this.form = {
                ...this.form,
                city: this.responsible_data.localidade,
                state: this.responsible_data.uf,
                place: this.responsible_data.logradouro,
                neighborhood: this.responsible_data.bairro,
              };
              this.loading_zip_code = false;
            })
            .catch(() => {
              this.loading_zip_code = false;
            });
        }
      }
    },

    async saveOrUpdate(payload) {
      if (this.isAdd) {
        delete payload.id;
        return await this[`add_${this.role}`](payload);
      }
      return await this[`edit_${this.role}`](payload);
    },

    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loadingSubmit = true;

        const payload = this.getFormDataObject();

        this.saveOrUpdate(payload).then(({ status }) => {
          this.loadingSubmit = false;
          if ([200, 201].includes(status)) {
            this.$emit("submited");
          }
          this.show_toast({
            message: "Empresa cadastrada com sucesso",
            type: "success",
          });
        });
        return;
      }
    },
    getFormDataObject() {
      const objectForm = {
        matrix: this.user.enterprise.id,
        id: this.branchId,
        social_reason: this.form.social_reason,
        fantasy_name: this.form.fantasy_name,
        segment: this.form.segment,
        email: this.form.email,
        // responsible_email: this.form.responsible_email,
        phone: this.form.phone,
        cnpj: this.form.cnpj?.replace(/[^\d]/g, ""),
        address: {
          place: this.form.place,
          number: this.form.number,
          complement: this.form.complement,
          zip_code: this.form?.zip_code?.replace(/[^\d]/g, ""),
          neighborhood: this.form.neighborhood,
          city: this.form.city,
          state: this.form.state,
          country: "Brasil",
        },
      };
      if (!objectForm.id) {
        delete objectForm.id;
      }
      return objectForm;
    },
    resetForm() {
      this.form = {
        cnpj: this.form.cnpj,
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      };
    },
    getEdit() {
      this.get_legal_personnel({ id: this.branchId }).then(({ data }) => {
        this.form = data;
        if (!data.address) {
          return;
        }
        const {
          zip_code,
          city,
          state,
          neighborhood,
          number,
          complement,
          place,
        } = data.address;

        this.form.zip_code = zip_code;
        this.form.city = city;
        this.form.state = state;
        this.form.complement = complement;
        this.form.neighborhood = neighborhood;
        this.form.number = number;
        this.form.place = place;
        // this.form.responsible_email = data.responsibles?.length
        //   ? data.responsibles[0]?.email
        //   : data.default_responsible?.email;

        this.$forceUpdate();
      });
    },
    handleConfirmDelete() {
      if (this.form.is_main || this.branchId === this.user.id) {
        return;
      }
      this.show_delete({
        in_delete: true,
        message: `Tem certeza que deseja excluir a filial ${this.form.social_reason}?`,
      });
    },
    handleDeleteLegalBranch() {
      if (this.branchId) {
        this[`delete_${this.role}`](this.branchId).then(() => {
          this.$emit("submited");
          this.show_delete({
            in_delete: false,
          });
        });
      }
    },
  },
  mounted() {
    this.role = this.user.role;
    if (!this.isAdd) {
      this.getEdit();
    }
    this.$root.$once("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.handleDeleteLegalBranch();
      }
    });
  },
};
</script>
