<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div class="input-search mb-4 col-md-5 p-0">
        <b-form-input
          autocomplete="off"
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
        />
        <b-button
          variant="light"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </div>
      <b-button @click="handleAddNewBranche()" class="btn-add">
        Adicionar filial
      </b-button>
    </div>
    <empty-list text="Não há filiais cadastradas" v-if="this.total === 0" />

    <cards-list grid="3" v-else gap="12">
      <b-card
        v-for="(item, index) in items"
        :key="index"
        class="card-hoverable pointer"
        @click="handleEditBranch(item)"
      >
        <section class="d-md-block">
          <div class="d-flex align-items-start justify-content-between mb-2">
            <span class="d-block w-100">
              {{ item.social_reason }}
            </span>
          </div>
          <section>
            <strong>CNPJ:</strong>
            {{ item.cnpj | VMask("##.###.###/####-##") }}
          </section>
        </section>
      </b-card>
    </cards-list>
    <b-modal
      id="add-branche"
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      :title="
        `${isAdd ? 'Adicionar' : 'Editar'} filial ${this.selectedBranche.name ||
          ''}`
      "
    >
      <Form
        :branchId="selectedId"
        :isAdd="isAdd"
        @submited="handleCloseModal"
      />
    </b-modal>

    <b-pagination
      v-if="total > 10"
      class="mt-4"
      @change="handleChangePage"
      v-model="current_page"
      :total-rows="total"
      :per-page="per_page"
      aria-controls="my-table"
    />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import Form from "./form";
import { mapGetters } from "vuex";

export default {
  mixins: [listPaginated],

  components: {
    Form,
  },

  data() {
    return {
      isAdd: false,
      paginate: true,
      selectedId: "",
      params: {},
      selectedBranche: {
        id: null,
        name: null,
      },
      provider: null,
      status: 1,
    };
  },

  beforeMount() {
    this.action_name = this.user.role;
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    handleAddNewBranche() {
      this.isAdd = true;
      this.$bvModal.show("add-branche");
    },

    handleEditBranch(branche) {
      this.isAdd = false;
      this.selectedBranche = branche;
      this.selectedId = this.selectedBranche.id;
      this.$bvModal.show("add-branche");
    },

    resetVariables() {
      this.selectedBranche = {};
      this.selectedId = "";
    },

    handleCloseModal() {
      this.resetVariables();
      this.$bvModal.hide("add-branche");
      this.getList();
    },

    handleSelectprovider(provider) {
      this.$emit("open_drawer", { item: { ...provider } });
      this.provider = provider.item;
    },

    handleSetStatus(status) {
      this.status = status;
    },
  },

  created() {
    this.params.matrix = this.user?.enterprise?.id;
    this.$root.$on("bv::modal::hidden", (bevent, id) => {
      if (id === "add-branche") {
        this.resetVariables();
      }
    });
  },
};
</script>
